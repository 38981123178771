/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";
export default function Cadre_02(props) {
  const { nodes, materials } = useGLTF("/cadre_02.glb");
  return (
    <RigidBody>

        <group {...props} dispose={null}>
        <group scale={[2.67, 1.71, 1.88]}>
            <mesh
                castShadow
            geometry={nodes.ml005.geometry}
            material={materials["Material.008"]}
            />
            <mesh
                castShadow
            geometry={nodes.ml005_1.geometry}
            material={materials["Material.006"]}
            />
            <mesh
                castShadow
            geometry={nodes.ml005_2.geometry}
            material={materials["Material.007"]}
            />
        </group>
        </group>


    </RigidBody>
  );
}

useGLTF.preload("/cadre_02.glb");
