
import { Text } from "@react-three/drei"

export default function Photoj(){

    return <>
    

    <Text
        // font='./fonts/Cabin-Regular.woff'
        color='white'

        position={[-30, .1, 3.7]}
        rotation={[-Math.PI/2, 0, 0]}
        fontSize={.5}
        textAlign='left'

        outlineColor='white'
        outlineWidth={.01}
        
        // rotation-x={Math.PI * 1.5}
    >
        Photo-journaliste
    </Text>

    <Text
        // font='./fonts/Cabin-Regular.woff'
        color='midnightblue'

        position={[-29.85, .1, 4.2]}
        rotation={[-Math.PI/2, 0, 0]}
        fontSize={.2}
        textAlign='left'


        // outlineColor='midnightblue'
        // outlineWidth={.01}
        maxWidth={4}
        letterSpacing={.15}

        
        // rotation-x={Math.PI * 1.5}
    >
        Jeune Afrique, LeDesk, Telquel, ANA, L'Alsace, L'Humanité ...
    </Text>
    <Text
        // font='./fonts/Cabin-Regular.woff'
        color='white'

        position={[-29.3, .1, 5.2]}
        rotation={[-Math.PI/2, 0, 0]}
        fontSize={.5}
        textAlign='left'

        outlineColor='white'
        outlineWidth={.01}
        
        maxWidth={7}
        // rotation-x={Math.PI * 1.5}
    >
        Photographe freelance
    </Text>

    <Text
        // font='./fonts/Cabin-Regular.woff'
        color='midnightblue'

        position={[-29.8, .1, 5.7]}
        rotation={[-Math.PI/2, 0, 0]}
        fontSize={.2}
        textAlign='left'


        // outlineColor='midnightblue'
        // outlineWidth={.01}
        maxWidth={4}
        letterSpacing={.15}

        
        // rotation-x={Math.PI * 1.5}
    >
        Cosumar, Marjane, ONMT, OCP, Renault/Somaca...
    </Text>
    <Text
        // font='./fonts/Cabin-Regular.woff'
        color='white'

        position={[-29.3, .1, 7.75]}
        rotation={[-Math.PI/2, 0, 0]}
        fontSize={.3}
        textAlign='left'



        maxWidth={5}
        lineHeight={1.15}
        letterSpacing={.15}

        
        // rotation-x={Math.PI * 1.5}
    >
        Photographe de presse, j'ai réalisé des reportages et des portrais de personnalités pour de nombreux médias internationaux pendant une décénie avant d'étendre mon activité à la photographie corporate 
    </Text>
    <Text
        // font='./fonts/Cabin-Regular.woff'
        color='gainsboro'
        
        position={[-32.2, .1, 8.2]}
        rotation={[-Math.PI/2, 0, Math.PI / 2]}
        fontSize={.3}
        textAlign='left'
      


        outlineColor='gainsboro'
        outlineWidth={.01}

        letterSpacing={.15}

        
        // rotation-x={Math.PI * 1.5}
    >
        2008 - 2020
    </Text>
    
    
    </>
}