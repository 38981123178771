import { RigidBody } from "@react-three/rapier"
import { useFrame } from "@react-three/fiber"
import { useKeyboardControls, useGLTF, Float } from "@react-three/drei"
import { useRef, useEffect } from "react"
import * as THREE from "three"
// import { lerp } from "three/src/math/MathUtils"
import { useState } from "react"

import useGame from "./stores/useGame.js"

export default function Boat(){

    const { nodes, materials } = useGLTF("/boat_notxt.glb")

    const [ subscribeKeys, getKeys ] = useKeyboardControls()

    const body = useRef()

    const [ smoothedCameraPosition ] = useState( () => new THREE.Vector3())
    const [ smoothedCameraTarget ] = useState( () => new THREE.Vector3())


    const cubePositionArr = useGame((state) => {
      return state.cubePosition
    })

    const CubeColorBlue = useGame((state) => {

      return state.CubeColorBlue
    })
    const CubeColorRed = useGame((state) => {

      return state.CubeColorRed
    })


    useFrame((state, delta) =>{

      // console.log(delta)
      
      // 
      // Controls
      // 
      const bodyPosition = body.current.translation()
      
      const { forward, backward, leftward, rightward, space } = getKeys()
      
      const impulse = { x: 0, y: 0, z: 0 }
      
      const impulseStrength = 40 * delta
      
      const cubePosition = new THREE.Vector3()
      cubePosition.fromArray(cubePositionArr)
      const distanceToCube = cubePosition.distanceTo(bodyPosition)
      
      


      // if (bodyPosition.y != 0.124){
      //   bodyPosition.y = 0.124
      // }
      // console.log(distanceToCube);

        
        const eastQuat = new THREE.Quaternion(
          0,
          -0.75,
          0,
          0.75
        )
          
        const southQuat = new THREE.Quaternion(
          0,
          1,
          0,
          0
        )
            
        const westQuat = new THREE.Quaternion(
          0,
          0.75,
          0,
          0.75
        )
            
        const northQuat = new THREE.Quaternion(
          0,
          0,
          0,
          1
        )

        if(forward)
        {

          impulse.z -= impulseStrength
          

          body.current.setRotation(northQuat) 

        }
        
        if(rightward)
        {

          impulse.x += impulseStrength            
          body.current.setRotation(eastQuat)   
                              
        }
        
        if(backward)
        {

          impulse.z += impulseStrength
          body.current.setRotation(southQuat) 

        }
        
        if(leftward)
        {

          impulse.x -= impulseStrength
          body.current.setRotation(westQuat) 

        }
          
          

        body.current.applyImpulse(impulse)
        // body.current.addForce(impulse)




        // 
        // Camera
        // 

        
        const cameraPosition = new THREE.Vector3()
        cameraPosition.copy(bodyPosition)
        cameraPosition.x += 4
        cameraPosition.z += 12
        cameraPosition.y += 10

        const cameraTarget = new THREE.Vector3()
        cameraTarget.copy(bodyPosition)
        cameraTarget.y += 1

        smoothedCameraPosition.lerp(cameraPosition, 5 * delta)
        smoothedCameraTarget.lerp(cameraTarget, 5 * delta)

        state.camera.position.copy(smoothedCameraPosition)
        state.camera.lookAt(smoothedCameraTarget)

        //
        // Distance to objects
        //

        // if(distanceToCube < 5){

        //   CubeColorBlue()
          
        // } else {

        //   CubeColorRed()
        // }



    })

    useEffect(() => {
      const bodyPosition = body.current.translation()

        const unsubscribeLog = subscribeKeys((state)=>{
            return state.log

        }, 
        (value) => {
            if(value){

                console.log(bodyPosition)

                


            }

        })
        return () => {

            unsubscribeLog()
        }


    },[])
    return <>


    <RigidBody 
        
        ref={ body } 
        colliders="cuboid"
        mass={1.5}
        // restitution={ 0 }
        friction={ .5 }
        linearDamping={ 0.1 }
        angularDamping={ 5 }
        enabledRotations={ [false, true, false, true]}
        // enabledTranslations={[true, false, true, true]}
        
        // Départ
        position={[6, 0, 11.3]}
        
        // position={[-28,0,8.3]}


        // position={[-14.5, 0, -24.7]}

          // position={[27.5, 1, -17]}
        >
          <Float
                rotationIntensity={.001}
                floatingRange={[-.005, .005]}
                floatIntensity={.5}
                speed={15}

          >
        <group dispose={null}
            rotation={[ 0, Math.PI /2, 0]}
            position={[0, 1, 0]}
            >
          <group

            
            
            >
            <mesh
              castShadow
              //   receiveShadow
              geometry={nodes.boat_1.geometry}
              material={materials.sails}
              />


            <mesh
                castShadow
                //   receiveShadow
                geometry={nodes.boat_2.geometry}
                material={materials.boat}
            />

            <mesh
            //   castShadow
            //   receiveShadow
              geometry={nodes.boat_3.geometry}
              material={materials.cabin}
              />
            <mesh
            //   castShadow
              // receiveShadow
              geometry={nodes.boat_4.geometry}
              material={materials.deck}
              />
            
          </group>

        </group>
      </Float>
    </RigidBody>
  </>






    // {/* Group test */}
    // return <>
    //         <RigidBody
    //             ref={ body}
    //             colliders="cuboid"
                
    //             restitution={ 0.2 }
    //             friction={ .7}
    //         >



    //             <group
    //                 position={[0, 1, 0]}
    //             >
    //                 <mesh
    //                     castShadow
    //                     position={[0, 0, 0]}
    //                 >
    //                     <boxGeometry  args={[3, 1, 5]} />
    //                     <meshStandardMaterial color="orangered" />
    //                 </mesh>
    //                 <mesh
    //                     position={[0, 1.01, -0.85]}
    //                 >
    //                     <boxGeometry  args={[2.5, 1, 1]} />
    //                     <meshStandardMaterial color="orangered" />
    //                 </mesh>
    //             </group>
    //         </RigidBody>

    

    // </>
}