/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Journey(props) {
  const { nodes, materials } = useGLTF("/journey.glb");
  return (
    <group {...props} dispose={null}>
      <mesh castShadow geometry={nodes.tri.geometry} material={materials.Material} />
    </group>
  );
}

useGLTF.preload("/journey.glb");
