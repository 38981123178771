import create from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'

export default create( subscribeWithSelector(
    ( set ) => {

        return {

            materialColor: "royalblue",
            cubePosition: [ -4, 1.5 , 7],

            phase: 'close',
            phase: 'far',

            CubeColorBlue: () =>{
                set(() =>{
                    // materialColorRed
                    return  {materialColor: 'blue'} 

                })
            },
            CubeColorRed: () =>{
                set(() =>{
                    // materialColorRed
                    return  {materialColor: 'red'} 

                })
            }
        }
    
    
    }
))