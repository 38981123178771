/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

import { RigidBody } from "@react-three/rapier";

export default function Cadre_01(props) {
  const { nodes, materials } = useGLTF("/cadre_01.glb");
  return (
    <RigidBody>

        <group {...props} dispose={null}>
        <group scale={[2.67, 1.71, 1.88]}>
            <mesh
            castShadow 
                geometry={nodes.ml.geometry} 
                material={materials.Material} />
            <mesh
            castShadow
            geometry={nodes.ml_1.geometry}
            material={materials["Material.001"]}
            />
            <mesh
            castShadow
            geometry={nodes.ml_2.geometry}
            material={materials["Material.002"]}
            />
        </group>
        </group>

    </RigidBody>
  );
}

useGLTF.preload("/cadre_01.glb");
