import { Text, useTexture } from "@react-three/drei"
export default function Web(){

    const email= 'david@davidrodrigues.fr'

    const emailClicked = () => {
        console.log('cliked!!')
        window.open('mailto:david@davidrodrigues.fr?subject=Super site web David !')       
    }
    const ciplaClicked = () => {
        window.open('https://www.dixsept.ma')       
    }

    const Hovered = () =>{
        document.body.style.cursor = 'pointer'
    }
    const Exited = () => {
        document.body.style.cursor = 'default'
    }

    const textureDixsept = useTexture('./screenDixsept.png')


    return (<>
        <Text
        color='silver'

        position={[25.9, 6.2, -6.4]}
        fontSize={.31}
        rotation-x={Math.PI * 1.5}
        textAlign='left'

        outlineColor='silver'
        outlineWidth={.01}
        >
            On discute d'un projet ?
        </Text>
        <Text
            color='silver'

            position={[25.75, 6.2, -6]}
            fontSize={.2}
            rotation-x={Math.PI * 1.5}
            textAlign='left'

            onClick= { emailClicked }
            onPointerEnter={ Hovered }
            onPointerLeave={ Exited }

        >
            { email}
        </Text>

        {/*
            dixsept.ma
        
        */}

        <Text
        // font='./fonts/Cabin-Regular.woff'
        color='white'

        position={[29.5, .1, -16.6]}
        rotation={[-Math.PI/2, 0, 0]}
        fontSize={.5}
        textAlign='left'

        outlineColor='white'
        outlineWidth={.01}
        
        // rotation-x={Math.PI * 1.5}
    >
        Développeur Web
    </Text>

    <Text
        // font='./fonts/Cabin-Regular.woff'
        color='midnightblue'

        position={[28.55, .1, -16.1]}
        rotation={[-Math.PI/2, 0, 0]}
        fontSize={.3}
        textAlign='left'


        // outlineColor='midnightblue'
        // outlineWidth={.01}

        letterSpacing={.15}

        onClick= { ciplaClicked }
        onPointerEnter={ Hovered }
        onPointerLeave={ Exited }

        
    >
        dixsept.ma
    </Text>
    <Text
        // font='./fonts/Cabin-Regular.woff'
        color='white'

        position={[30.1, .1, -14.2]}
        rotation={[-Math.PI/2, 0, 0]}
        fontSize={.3}
        textAlign='left'



        maxWidth={5}
        lineHeight={1.15}
        letterSpacing={.15}

        
        // rotation-x={Math.PI * 1.5}
    >
        Création du site web d'archivage des éléments de la campagne médiatique de sensibilisation autour des questions de santé mentale. Réalisé pour le compte du groupe pharmaceutique Cipla Maroc.
    </Text>
    <Text
        // font='./fonts/Cabin-Regular.woff'
        color='gainsboro'
        
        position={[27.2, .1, -13.3]}
        rotation={[-Math.PI/2, 0, Math.PI / 2]}
        fontSize={.3}
        textAlign='left'
      


        outlineColor='gainsboro'
        outlineWidth={.01}

        letterSpacing={.15}

        
        // rotation-x={Math.PI * 1.5}
    >
        2021
    </Text>

    {/* 
        Texture        
    */}

    <mesh
        
        position={[28.5, 1, -17.6]}
        scale={2}
        onClick= { ciplaClicked }
        onPointerEnter={ Hovered }
        onPointerLeave={ Exited }
        
    >
        <planeGeometry/>
        <meshBasicMaterial
            map={ textureDixsept }
        />

    </mesh>




    </>


    )
}