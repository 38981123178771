/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF, Text } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";


export default function Tc(props) {
  const { nodes, materials } = useGLTF("/tc.glb");

  const tcClicked = () => {
    window.open('https://troisiemechambre.com')
}


const Hovered = () =>{
    document.body.style.cursor = 'pointer'
}

const Exited = () => {
    document.body.style.cursor = 'default'
}

  return (<>
  
  
    <RigidBody
      colliders='trimesh'
      type='fixed'
    >

      <group {...props} dispose={null}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.appart.geometry}
          material={materials["Material.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.appart_1.geometry}
          material={materials["Material.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.appart_2.geometry}
          material={materials.Material}
        />
      </group>
    </RigidBody>
    <Text
        // font='./fonts/Cabin-Regular.woff'
        color='black'

        position={[-23.8, 2.35, -29.5]}
        rotation={[0, Math.PI / 2, 0]}
        fontSize={.8}
        textAlign='left'

        outlineColor='black'
        outlineWidth={.01}
        
        // rotation-x={Math.PI * 1.5}
    >
        TROISIÈME
    </Text>
    <Text
        // font='./fonts/Cabin-Regular.woff'
        color='black'

        position={[-19.85, .2, -31.8]}
        rotation={[0, 0, 0]}
        fontSize={.65}
        textAlign='left'

        outlineColor='black'
        outlineWidth={.01}
        
        // rotation-x={Math.PI * 1.5}
    >
        CHAMBRE
    </Text>

    <Text
        // font='./fonts/Cabin-Regular.woff'
        color='white'

        position={[-14.8, .1, -28]}
        rotation={[-Math.PI/2, 0, 0]}
        fontSize={.5}
        textAlign='left'

        outlineColor='white'
        outlineWidth={.01}
        
        // rotation-x={Math.PI * 1.5}
    >
        Co-Fondateur / CEO
    </Text>

    <Text
        // font='./fonts/Cabin-Regular.woff'
        color='midnightblue'

        position={[-14.95, .1, -27.5]}
        rotation={[-Math.PI/2, 0, 0]}
        fontSize={.3}
        textAlign='left'


        // outlineColor='midnightblue'
        // outlineWidth={.01}

        letterSpacing={.15}

        onClick= { tcClicked }
        onPointerEnter={ Hovered }
        onPointerLeave={ Exited }

        
    >
        troisiemechambre.com
    </Text>
    <Text
        // font='./fonts/Cabin-Regular.woff'
        color='white'

        position={[-14.45, .1, -24.9]}
        rotation={[-Math.PI/2, 0, 0]}
        fontSize={.3}
        textAlign='left'



        maxWidth={5}
        lineHeight={1.15}
        letterSpacing={.15}

        
        // rotation-x={Math.PI * 1.5}
    >
        Startup propTech fondée à Casablanca ( Maroc ) en 2020, Troisième Chambre accompagne la digitalisation du secteur immobilier en proposant de créer un jumeau numérique d'un bien en 3D à partir de son plan de masse et de l'afficher ensuite sur internet accompagné d'un QR-Code 
    </Text>
    <Text
        // font='./fonts/Cabin-Regular.woff'
        color='gainsboro'
        
        position={[-17.2, .1, -23.9]}
        rotation={[-Math.PI/2, 0, Math.PI / 2]}
        fontSize={.3}
        textAlign='left'
      


        outlineColor='gainsboro'
        outlineWidth={.01}

        letterSpacing={.15}

        
        // rotation-x={Math.PI * 1.5}
    >
        2020 - 2023
    </Text>

    
  </>
  );
}

useGLTF.preload("/tc.glb");
