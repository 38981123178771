/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

import { RigidBody, } from "@react-three/rapier";
import { Float } from "@react-three/drei";
export default function Buoy(props) {
  const { nodes, materials } = useGLTF("./buoy.glb");
  return (
    <RigidBody
        colliders='hull'
        mass={0.05}
        restitution={ 1 }
        friction={ 0 }
        linearDamping={ 1 }
        angularDamping={ 5 }
        enabledRotations={ [false, true, false, true]}

    >
        <Float
            rotationIntensity={.07}
            floatingRange={[-0.2, -0]}
            floatIntensity={0}
            speed={3}
        
        
        >
            <group {...props} dispose={null} >
            <mesh
                castShadow
                geometry={nodes.Cylinder001.geometry}
                material={materials["Material.002"]}
            />
            <mesh
                castShadow
                geometry={nodes.Cylinder001_1.geometry}
                material={materials["Material.001"]}
            />
            <mesh
                castShadow
                geometry={nodes.Cylinder001_2.geometry}
                material={materials["Material.003"]}
            />
            </group>

        </Float>

    </RigidBody>
  );
}

useGLTF.preload("/buoy.glb");
