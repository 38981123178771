import { OrbitControls, Trail, Text, Sparkles } from '@react-three/drei'
import { Physics, Debug, RigidBody,  } from '@react-three/rapier'

import { Perf } from 'r3f-perf'
import Lights from './Lights.js'
// import Level from "./Level.js"
import Islands from "./Islands.js"
import Boat from "./Boat.js"
import Buoy from './Buoy.js'
import Bouee from './Bouee.js'
import Dock from './Dock.js'
import Tc from './Tc.js'
import Web from './Web.js'
import Photoj from './Photoj.js'

import Css from './Css.js'
import Html from './Html.js'
import Js from './Js.js'
import Blender from './Blender.js'
import Rea from './Rea.js'
import Odin from './Odin.js'
import Journey from './Journey.js'
import Ltc from './Ltc.js'

import Cadre_01 from './Cadre_01.js'
import Cadre_02 from './Cadre_02.js'
import Cadre_03 from './Cadre_03.js'

// import * as THREE from 'three'
import { useRef, Suspense} from 'react'


// softShadows({
//     frustum: 3.75,
//     size: 0.005,
//     near: 9.5,
//     samples: 2,
//     rings: 11
// })



export default function Experience()
{
    
    const email = 'david@davidrodrigues.fr'

    const emailClicked = () => {
        window.open('mailto:david@davidrodrigues.fr?subject=Super site web David !')
    }

    const Hovered = () =>{
        document.body.style.cursor = 'pointer'
    }

    const Exited = () => {
        document.body.style.cursor = 'default'
    }


    return <>
        <OrbitControls makeDefault />

        {/* <Perf position='bottom-left' minimal={false}/> */}



        <Physics>



            
            {/* <gridHelper /> */}

            <Lights />


            {/* 
                Web Dev
            */}


            {/* 
                <Level />
            */}

            <Suspense>






                {/* 
                    Texte description
                */}

                <Text
                    // font='./fonts/Cabin-Regular.woff'
                    color='midnightblue'

                    position={[-1.8, 1.5, 1.7]}
                    fontSize={3}
                    textAlign='left'

                    outlineColor='midnightblue'
                    outlineWidth={.05}
                    
                    // rotation-x={Math.PI * 1.5}
                >
                    David Rodrigues

                </Text>

                <Text
                    color='white'

                    position={[-2.95, 0.01, 2.6]}
                    fontSize={1.5}
                    rotation-x={Math.PI * 1.5}
                    textAlign='left'

                    outlineColor='white'
                    outlineWidth={.04}
                >
                    Développeur Web / Front-End

                </Text>

                <Text
                    color='white'

                    position={[-11.1, 0.01, 3.9]}
                    fontSize={.5}
                    rotation-x={Math.PI * 1.5}
                    textAlign='left'

                    // outlineColor='white'
                    // outlineWidth={.02}
                >
                    3D & UI / UX 

                </Text>

                <Text
                    
                    color='white'
                    position={[-10.6, 0.01, 5.5]}
                    fontSize={.5}
                    rotation-x={Math.PI * 1.5}
                    textAlign='left'
                    maxWidth={10}
                    lineHeight={1.15}
                    

                    outlineColor='white'
                    outlineWidth={.02}
                >
                    COMPETENCES :
                </Text>

                <Text
                    
                    color='white'
                    position={[-9, 0.01, 7.4]}
                    fontSize={.5}
                    rotation-x={Math.PI * 1.5}
                    textAlign='left'
                    maxWidth={5}
                    lineHeight={1.15}

                    // outlineColor='weat'
                    // outlineWidth={.11}
                >
                    Html, Css, Javascript, React, WebGL, Blender, Photoshop, Figma
                </Text>
                
                <Text
                    
                    color='white'
                    position={[-11.2, 0.01, 9.8]}
                    fontSize={.5}
                    rotation-x={Math.PI * 1.5}
                    textAlign='left'
                    maxWidth={10}
                    lineHeight={1.15}

                    outlineColor='white'
                    outlineWidth={.02}
                >
                    CURSUS :
                </Text>

                <Text
                    
                    color='white'
                    position={[-9, 0.01, 11]}
                    fontSize={.5}
                    rotation-x={Math.PI * 1.5}
                    textAlign='left'
                    maxWidth={5}
                    lineHeight={1.15}

                    // outlineColor='weat'
                    // outlineWidth={.11}
                >
                    The Odin Project, ThreeJS Journey
                </Text>


                <Text

                    color='white'
                    position={[-9.3, 0.01, 12.7]}
                    fontSize={.5}
                    rotation-x={Math.PI * 1.5}
                    textAlign='left'
                    maxWidth={10}
                    lineHeight={1.15}

                    outlineColor='white'
                    outlineWidth={.02}

                    onClick= { emailClicked }
                    onPointerEnter={ Hovered }
                    onPointerLeave={ Exited }
                    
                    >
                    { email }
                </Text>
        
                
                {/* 
                    Texte Déplacement
                */}

                <Text
                    color='white'

                    position={[8.8, 0.01, 10.3]}
                    fontSize={.5}
                    rotation-x={Math.PI * 1.5}
                    textAlign='left'

                    outlineColor='white'
                    outlineWidth={.01}
                >
                    DÉPLACEMENTS

                </Text>
                <Text
                    color='white'
                    
                    position={[9.3, 0.01, 11.5]}
                    fontSize={.35}
                    rotation-x={Math.PI * 1.5}
                    textAlign='left'
                    maxWidth={4.2}
                    lineHeight={ 1.15 }


                >
                    Utilisez les flèches du clavier ou les touches ZQSD / WASD

                </Text>

                {/* 
                    Texte Hire me!
                */}

                <Text
                    color='silver'

                    position={[-21.7, 6.5, 30.3]}
                    fontSize={.35}
                    rotation-x={Math.PI * 1.5}
                    textAlign='left'

                    outlineColor='silver'
                    outlineWidth={.01}
                >
                Contactez-moi !
                </Text>

                <Text
                    color='silver'

                    position={[-21.7, 6.5, 29.8]}
                    fontSize={.2}
                    rotation-x={Math.PI * 1.5}
                    textAlign='left'

                    onClick= { emailClicked }
                    onPointerEnter={ Hovered }
                    onPointerLeave={ Exited }

                >
                { email}
                </Text>



                {/* 
                    OBJETS
                */}

                <Islands
                
                />

                <Dock 
                    position={[14.2, 0.3, 13.4]}
                    
                />

                <Tc
                    position={[0, -.04, 2]}
                    scale={22}
                />

                <Web

                />

                <Photoj
                
                />



                {/* 
                    LOGOS
                */}

                <RigidBody
                    colliders='cuboid' 
                    mass={.8}
                    >

                        <Js
                            position={[-12, 5, 7]}
                            rotation={[ Math.PI /2, 0, 0]}
                            scale={[2, 4, 2]}
                        
                        />
                </RigidBody>

                <RigidBody
                    colliders='cuboid'
                    mass={.8}
                >
                    <Css 
                        scale={[2, 4, 2]}
                        position={[-10, 1, 7]} 
                    />
                </RigidBody>

                <RigidBody
                    colliders='cuboid'
                    mass={.8}
                >
                    <Html
                        scale={[2, 4, 2]}
                        position={[-8, 2, 7]}
                        rotation={ [0, Math.PI/3, Math.PI /2]}

                    
                    />
                </RigidBody>


                <RigidBody
                    colliders='cuboid'
                    mass={.8}
                >
                    <Rea
                        scale={[2, 4, 2]}
                        position={[-9, 2, 8.5]}
                    
                    />
                </RigidBody>

                <RigidBody
                    colliders='cuboid'
                    mass={.8}
                >
                    <Blender
                        scale={[2, 4, 2]}
                        position={[-10.5, 2, 8.5]}

                    
                    />
                </RigidBody>

                <RigidBody
                    colliders='cuboid'
                    mass={.8}
                >
                    <Odin
                        scale={[2, 4, 2]}
                        position={[-10.5, 3, 11]}
                    
                    />
                </RigidBody>

                <RigidBody
                    colliders='cuboid'
                    mass={.8}
                >
                    <Journey
                        scale={[2, 4, 2]}
                        position={[-8.5, 3, 11.5]}
                        rotation={[0, Math.PI/1.6, Math.PI/2]}
                    
                    />
                </RigidBody>

                <RigidBody
                    colliders='cuboid'
                    mass={.8}
                >
                    <Ltc
                        scale={[6, 6, 6]}
                        position={[-19.42, 1, -25]}
                        rotation={[0, 0, 0]}
                    
                    />
                </RigidBody>




                {/* 
                    Bateau
                */}
                <Trail
                    width={5} 
                    color={'white'} 
                    length={10} 
                    decay={1} 
                    local={true} 
                    stride={0} 
                    interval={1} 
                    attenuation={(width) => width * 0.1 }
                >

                    <Boat/>

                </Trail> 


                {/* 
                    Sparkles
                */}

                <Sparkles

                    count={10}
                    size={6}
                    position={[25, 1, -16.4]}
                    speed={0.5}
                    scale={3}
                />
                <Sparkles

                    count={10}
                    size={6}
                    position={[-22.7, 1, 19.7]}
                    speed={0.5}
                    scale={3}
                />
                <Sparkles

                    count={10}
                    size={6}
                    position={[-16.3, 1, -26.1]}
                    speed={0.5}
                    scale={3}
                />
                <Sparkles

                    count={10}
                    size={6}
                    position={[-29.6, 1, 6.8]}
                    speed={0.5}
                    scale={3}
                />

                {/* 
                    Buoys
                */}


                <Buoy
                    position={[-6.2, -0.5, -14.5]}
                    scale={.8}
                    rotation={[0, Math.PI * .5 , 0]}
                
                />
                <Buoy
                    position={[15.4, -0.5, -11.6]}
                    scale={.8}
                    rotation={[0, Math.PI *2 , 0]}
                
                />
                <Buoy
                    position={[-27.8, -.5, 13.18]}
                    scale={.8}
                    rotation={[0, Math.PI/2*3 , 0]}
                />


                {/* 
                    Cadres
                */}



                <Cadre_01
                scale={.9}
                position={[-30.5, 4, -3.5]}
                // rotation={[Math.PI / 2, Math.PI *2, 0]}
                rotation={[Math.PI/2, Math.PI/2 * 3, 0]}                
                />

                <Cadre_02
                scale={1.2}
                position={[-34.5, 4, 2.8]}
                // rotation={[Math.PI / 2, Math.PI *2, 0]}
                rotation={[Math.PI/2, 0, -0.4]}               
                />

                <Cadre_03
                scale={.6}
                position={[-26.7, 4, 1.2]}
                // rotation={[Math.PI / 2, Math.PI *2, 0]}
                rotation={[Math.PI/2, 0, 0.45]}               
                />
                
                
                <Bouee
                    position={[2.4, 4, -13.7]}
                    rotation={[0, 0, 0]}
                    scale={1}
                
                />
                <Bouee
                    position={[-24.5, 2, 17.4]}
                    rotation={[0, 0, 0]}
                    scale={1}
                
                />



            </Suspense>


            {/* <Debug/> */}

            {/* <axesHelper /> */}

        </Physics>
    </>
}