import { useRef } from "react"
import { useFrame } from "@react-three/fiber"

export default function Lights()
{

    // const light = useRef()

    // useFrame( (state) => {

    //     light.current.position.z = state.camera.position.z +1
    //     light.current.position.x = state.camera.position.x +1

    //     light.current.target.position.z = state.camera.position.z +1
    //     light.current.target.position.x = state.camera.position.x -30

    //     light.current.target.updateMatrixWorld()

    // })

    return <>
        <directionalLight
            // ref={ light }
            castShadow
            position={ [ 50, 25, 15 ] }
            intensity={ 1.9 }
            shadow-mapSize={ [ 512, 512 ] }
            shadow-camera-near={ 1 }
            shadow-camera-far={ 120 }
            shadow-camera-top={ 120 }
            shadow-camera-right={ 120 }
            shadow-camera-bottom={ - 120 }
            shadow-camera-left={ - 120 }
        />
        <ambientLight intensity={ 0.5 } />
    </>
}