/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF, } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";



export default function Islands(props) {
  const { nodes, materials } = useGLTF("./islands.glb");
  return (
    <RigidBody
    type='fixed'
    colliders={'trimesh'}
    position={[0, 0, 0]}
    
    >

        <group {...props} dispose={null} scale={[22, 25, 22]} >
        <mesh geometry={nodes.grass.geometry} material={materials.grass} />
        <mesh
            
            geometry={nodes.sea.geometry}
            material={materials.sea}
            scale={1.5}
            receiveShadow
            
        />
        <mesh castShadow geometry={nodes.snow.geometry} material={materials.snow} />
        <mesh castShadow geometry={nodes.sand.geometry} material={materials.sand} />
        <mesh castShadow geometry={nodes.stone.geometry} material={materials.sail} />
        </group>

        {/* <mesh
            receiveShadow
        
        >
            <boxGeometry args={[200, 0.5, 200]} />

        </mesh> */}

    </RigidBody>
  );
}

useGLTF.preload("/islands.glb");
