import { useState, useEffect } from "react"

import { useRef } from "react"

export default function Interface() {

    let [width, setWidth] = useState(null)
    let [height, setHeight] = useState(null)
    let [cursor, setCursor] = useState('pointer')
    let [display, setDisplay] = useState('none')



    const textDiv = useRef()


    return <>
        <div className="interface" id="interface">
            <div 
                className="material-symbols-outlined file_open"
                style={{
                    width: width,
                    height: height,
                    cursor: cursor,
                    border: '1px solid white'

                    
                }}
                onMouseDown={() => {
                    // console.log('down')
                    
                    setWidth( width =  '50vw' )
                    setHeight( height = '50vh' )
                    setCursor( cursor = 'grabbing')

                    setDisplay( textDiv.current.display = 'block')

                }}
                onMouseUp={() =>{
                    // console.log('up')
                    
                    setWidth( width = '24px' )
                    setHeight( height = '24px' )
                    setCursor( cursor='pointer' )
                    
                    setDisplay( textDiv.current.display = 'none')


                }}
            >
            help
                <div
                    className="textHelper"
                    ref={ textDiv }
                    style={{
                        display: display,
                    
                        
                    }}
                >
                    <h1>
                        DAVID RODRIGUES
                    </h1>
                    <h2 style={{
                        color: 'black',
                        marginTop: '-16px'
                    }} >
                        Développeur web / Front-End
                    </h2>
                    <h3>
                        Html, Css, Js, WebGL, React
                    </h3>
                    <h3 style={{
                        marginTop: '-12px'
                    }} >
                        Blender, Figma, Photoshop
                    </h3>
                    <h3 style={{
                        marginTop: '2px',
                        
                        
                    }} >
                        Photographe de presse depuis toujours interessé <br></br>
                        par le développement web, j'ai franchi le pas en <br></br>
                        2020 et me consacre aujourd'hui totalement à la<br></br>
                        création d'expérience en 3D sur navigateur web.
                    </h3>
                    <h2 style={{
                        color: 'white',
                        marginTop: '24px'
                        
                    }} >
                        david@davidrodrigues.fr
                    </h2>
                </div>
            </div>
        </div>
    </>
        
        
}