/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
import { RigidBody } from "@react-three/rapier";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Dock(props) {
  const { nodes, materials } = useGLTF("/dock.glb");
  return (
    <RigidBody>
      <group {...props} dispose={null} scale={22}>
        <mesh
          castShadow
          geometry={nodes.dock_02.geometry}
          material={materials.cabin}

        />
      </group>
    </RigidBody>
  );
}

useGLTF.preload("/dock.glb");
