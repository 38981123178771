/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
import { RigidBody } from "@react-three/rapier";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Cadre_03(props) {
  const { nodes, materials } = useGLTF("/cadre_03.glb");
  return (
    <RigidBody>

        <group {...props} dispose={null}>
        <group scale={[1.65, 1.71, 1.71]}>
            <mesh
            castShadow
            geometry={nodes.ml009.geometry}
            material={materials["Material.015"]}
            />
            <mesh
            castShadow
            geometry={nodes.ml009_1.geometry}
            material={materials["Material.013"]}
            />
            <mesh
            castShadow
            geometry={nodes.ml009_2.geometry}
            material={materials["Material.014"]}
            />
        </group>
        </group>

    </RigidBody>
  );
}

useGLTF.preload("/cadre_03.glb");
