import './style.css'
import ReactDOM from 'react-dom/client'
import { Canvas } from '@react-three/fiber'
import Experience from './Experience.js'
import Interface from './Interface.js'

import { KeyboardControls, Loader, } from '@react-three/drei'
import * as THREE from 'three'
const root = ReactDOM.createRoot(document.querySelector('#root'))

root.render(
    <KeyboardControls
        map={ [
            { name: 'forward', keys: [ 'ArrowUp', 'KeyW' ] },
            { name: 'backward', keys: [ 'ArrowDown', 'KeyS' ] },
            { name: 'leftward', keys: [ 'ArrowLeft', 'KeyA' ] },
            { name: 'rightward', keys: [ 'ArrowRight', 'KeyD' ] },
            { name: 'log', keys: [ 'Space' ] },
        ] }
    >

        <Canvas
            // gl={{
            //     toneMapping: THREE.ACESFilmicToneMapping, 
            //     outputEncoding: THREE.sRGBEncoding
                
            // }}
            shadows
            camera={ {
                fov: 45,
                near: 0.1,
                far: 60,
                position: [ 20, 20, 50 ]
            } }
        >
            <Experience />
        </Canvas>
        <Interface/>
        <Loader/>

    </KeyboardControls>
)