/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF, Float } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";


export default function Bouee(props) {
  const { nodes, materials } = useGLTF("/bouee.glb");
  return (
    <Float
        speed={ 4 }
        rotationIntensity={.01}
        floatingRange={[-0.8, -1]}
        floatIntensity={.5}
    >
        <RigidBody
            colliders='cuboid'
            mass={ 0.1 }
        >
            
            <group {...props} dispose={null} >
            <mesh castShadow geometry={nodes.Torus.geometry} material={materials.white} />
            <mesh castShadow geometry={nodes.Torus_1.geometry} material={materials.red} />
            </group>
        </RigidBody>
    </Float>
  );
}

useGLTF.preload("/bouee.glb");
